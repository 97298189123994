import React from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import { Link as AnchorLink } from 'react-scroll'

import { ButtonA } from '../components/Button'
import { H1, H2 } from '../components/Headings'
import Hero from '../components/Hero'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { ALink } from './A'
import PostExcerpt from './Post/Excerpt'
import HeroPopOver from './HeroPopOver'

const textShadow = `text-shadow: 1px 1px 7px #000000cc;`

const __Hero = ({ data }) => {
  return (
    <Hero
      id="hero"
      css={[
        tw`text-center md:(text-left) text-white py-20 mb-8 md:(p-0 mb-16) relative`,
        `background: linear-gradient(to right, rgba(25, 71, 152, .75) 0, rgba(0,0,0,.3) 30%,rgba(0,0,0,.1) 80% );
        @media (min-width: 768.98px) {
          background: linear-gradient(to right, rgba(25, 71, 152, .75) 0, rgba(0,0,0,.3) 30%, transparent 70% );
        }
        `,
        '@media(min-width: 768px) { height: 600px; }',
      ]}
      background={
        data?.backgroundImage?.localFile?.publicURL ||
        data?.backgroundImage?.sourceUrl
      }
    >
      <div
        tw="text-lg font-bold"
        dangerouslySetInnerHTML={{ __html: data.crown }}
      />
      <H1
        display
        css={[tw`mb-6 text-white leading-none`, textShadow]}
        dangerouslySetInnerHTML={{ __html: data.headline }}
      />
      <ButtonA
        href={data.cta.url}
        large
        tw="w-full mb-6 text-center sm:(w-auto)"
      >
        {data.cta.title} <FontAwesomeIcon icon={faChevronRight} tw="ml-4" />
      </ButtonA>
      <p css={[textShadow]}>
        Or Call{' '}
        <a href="tel:8002399898" tw="font-bold border-b-2 border-white">
          800-239-9898
        </a>
      </p>
      <PageDownButton />
    </Hero>
  )
}

const PageDownButton = () => (
  <AnchorLink
    to="services"
    smooth={true}
    duration={500}
    css={[
      tw`hidden md:(flex) absolute h-16 w-16 bg-white text-yellow-600 rounded-full shadow-lg transform -translate-x-1/2 -translate-y-1/2 items-center justify-center cursor-pointer
            transition ease-in-out duration-150
            hover:(bg-yellow-600 text-white)
            focus:(bg-yellow-600 text-white outline-none shadow-outline)`,
      'left: 50%; top: 100%;',
    ]}
  >
    <FontAwesomeIcon icon={faChevronDown} size="2x" />
  </AnchorLink>
)

const Services = props => {
  const { data } = props
  return (
    <div id="services" {...props}>
      <H2 tw="text-center md:(text-left)">{data.headline}</H2>
      <p tw="mb-6 text-center md:(text-left) text-gray-700">{data.paragraph}</p>
      <div tw="md:(grid grid-cols-2 col-gap-8 row-gap-8)">
        {data.services.map((service, i) => (
          <ALink
            key={`service-link-${i}`}
            to={service.link.url}
            className="group"
            tw="block mb-8 md:(m-0)"
          >
            {service?.image && (
              <>
                {service?.image?.localFile?.childImageSharp?.fluid ? (
                  <Img
                    fluid={service.image.localFile.childImageSharp.fluid}
                    tw="rounded-lg mb-2 transform shadow-none translate-y-0 group-hover:(-translate-y-2 shadow-lg) transition ease-in-out duration-200"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src={service.image?.sourceUrl}
                    tw="mb-2 transform shadow-none translate-y-0 group-hover:(-translate-y-2 shadow-lg) transition ease-in-out duration-200"
                    loading="lazy"
                  />
                )}
              </>
            )}
            <div
              tw="text-center md:(text-left)"
              css={[
                tw`font-condensed uppercase text-xl font-normal text-gray-900`,
                'font-stretch: condensed;',
              ]}
            >
              {service.headline}{' '}
              <span
                css={[
                  tw`font-extrabold text-yellow-600 text-4xl leading-4 inline-block transform group-hover:(translate-x-2) transition ease-in-out duration-200 relative`,
                  'top:.25rem;',
                ]}
              >
                →
              </span>
            </div>
          </ALink>
        ))}
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  )
}

const Blog = ({ data, ...remainingProps }) => {
  const {
    title,
    date,
    categories,
    featuredImage,
    excerpt,
    slug,
  } = data.nodes[0]

  return (
    <PostExcerpt
      tw="hidden md:(block)"
      title={title}
      date={date}
      categories={categories}
      featuredImage={featuredImage}
      excerpt={excerpt}
      slug={slug}
      {...remainingProps}
    />
  )
}

const Difference = ({ data, ...remainingProps }) => (
  <div {...remainingProps}>
    <H2 tw="text-center md:(text-left)">{data.headline}</H2>
    {data?.image && (
      <>
        {data.image?.localFile?.childImageSharp?.fluid ? (
          <Img
            fluid={data.image.localFile.childImageSharp.fluid}
            tw="mb-4 rounded-lg"
            loading="lazy"
          />
        ) : (
          <img src={data.image?.sourceUrl} tw="mb-4 w-full" loading="lazy" />
        )}
      </>
    )}
    <div
      tw="text-center md:(text-left) text-gray-700"
      dangerouslySetInnerHTML={{ __html: data.paragraph }}
    />
  </div>
)

const Satisfaction = props => {
  const { data } = props

  return (
    <div
      tw="bg-gradient-to-b from-blue-500 to-blue-700 p-10 rounded-lg"
      {...props}
    >
      <img
        src="/110-satisfaction-icon-white.png"
        tw="w-full mb-5"
        loading="lazy"
      />
      <div
        tw="text-white leading-relaxed text-center"
        dangerouslySetInnerHTML={{ __html: data.paragraph }}
      />
    </div>
  )
}

export default {
  Hero: __Hero,
  Services,
  Blog,
  Difference,
  Satisfaction,
}
