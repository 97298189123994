import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Home from '../components/Home'

import { graphql } from 'gatsby'
import Container from '../components/Container'
import Testimonials from '../components/Testimonials'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../helpers'

const IndexPage = ({ data, location, preview }) => {
  const pageData = preview ? preview : data
  return (
    <Layout location={location}>
      {!preview && <Seo post={formatYoastSeo(data.page)} />}

      <Home.Hero data={pageData.page.hero} />
      <Container>
        <div tw="grid grid-cols-1 row-gap-8 md:(row-gap-16) lg:(grid-cols-3 col-gap-16)">
          <Home.Services data={pageData.page.services} css={[tw`col-span-2`]} />
          <Home.Blog data={pageData.blog} />
          <Home.Difference
            data={pageData.page.difference}
            css={[tw`col-span-2`]}
          />
          <Home.Satisfaction data={pageData.page.satisfaction} />
        </div>
        <Testimonials.Carousel />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    page: wpPage(isFrontPage: { eq: true }) {
      title
      seo {
        ...PostSEO
      }
      hero: homeHero {
        crown
        headline
        subtext
        cta {
          title
          url
          target
        }
        backgroundImage {
          localFile {
            childImageSharp {
              fixed(width: 1920) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
            publicURL
          }
        }
      }
      services: homeServices {
        headline: servicesHeadline
        paragraph: servicesParagraph
        services {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          headline
          link {
            url
          }
        }
      }
      difference: homeDifference {
        headline: differenceHeadline
        paragraph: differenceParagraph
        image: differenceFeaturedImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      satisfaction: homeSatisfaction {
        headline: satisfactionHeadline
        paragraph: satisfactionParagraph
      }
    }
    blog: allWpPost(limit: 1, sort: { order: DESC, fields: date }) {
      nodes {
        date
        excerpt
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
